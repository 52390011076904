import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Footer from '../../components/Footer/Footer';
import IconButton from '../../components/IconButton/IconButton';
import Loader from '../../components/Loader/Loader';
import Map from '../../components/Map/Map';
import Sidebar from '../../components/Sidebar/Sidebar';
import BikeIcon from '../../components/svg/BikeIcon';
import { StoreContext } from '../../context/StoreContext';
import useCurrentRegion from '../../hooks/useCurrentRegion';
import useFetch from '../../hooks/useFetch';
import { translate } from '../../translate';

const Recommended: React.FC<RouteComponentProps> = ({ history, ...props }:any) => {
  const { state, actions } = useContext(StoreContext);
  const regionId = props.match.params.region;
  const region = useCurrentRegion(regionId);
  const coords:any = useFetch(`regio/coords/${regionId}`, false);
  const pois = useFetch('poi', true, { key: 'field_regio', value: regionId });
  const content = pois.length > 0 ? pois : [];
  const currentPoi = (state.activePoi) ? pois.filter((poi:any) => poi.nid === state.activePoi) : null;

  // On onmount, we unset the active marker.
  useEffect(() => {
    return actions.setActiveMarker(null, 'aanrader');
  }, []);

  return (
    <div className="page recommended">
      {region && content.length > 0 && coords.length > 0 ? (
        <>
          <Map polygons={coords[0].field_coordinates} region={region.tid} content={content} />
          {(currentPoi && currentPoi.length > 0) && (
              <Sidebar content={currentPoi} />
            )}
          {regionId != 1 && (
            <IconButton iconIsLongerThanWider={true} link='routes' hasSidebar={state.activePoi !== null} icon={<BikeIcon fill='#ffffff' />} text={translate('Walking and cycling routes', state.localeAbbr)} />
          )}
          <Footer />
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
};

export default withRouter(Recommended);
