import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import Card from '../../components/Card/Card';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import { StoreContext } from '../../context/StoreContext';
import useTranslatedRegions from '../../hooks/useTranslatedRegions';
import './Regions.scss';

interface IRegionImages {
  [key: string]: string;
}

// The map on top of card.
const getRegionImage = (regionId:string) => {
  const regionImages:IRegionImages = {
    '1': require('../../assets/img/map-meise.png'),
    '2': require('../../assets/img/map-brussel.png'),
    '3': require('../../assets/img/map-groene-gordel-leuven.png')
  };

  return regionImages[regionId];
};

const Regions: React.FC<RouteComponentProps> = (props) => {
  const { state } = useContext(StoreContext);
  const regions = useTranslatedRegions(state.locale);

  return (
    <div className="page regions">
      {regions && regions.length > 0 ? (
        <div className="card-wrapper">
          {regions.map((region:any, i:number) => {
            const link = `/categories/${region.tid}`;
            const regionImage = getRegionImage(region.tid);
            return (
              <Card key={i} title={region.name} topImage={regionImage} image={region.field_afbeelding} link={link} />
            );
          })}
          <Footer {...props} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default React.memo(Regions);