import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import Container from './components/Container/Container';
import './styles.scss';

const removeCoordsFromLocalStorage = () => {
  localStorage.removeItem('regio/coords/1');
  localStorage.removeItem('regio/coords/2');
  localStorage.removeItem('regio/coords/3');
}

const App = () => {
  ReactGA.initialize('UA-144356284-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    const now = new Date().getTime();
    const cached:any = localStorage.getItem('coordsCache');
    const dayInMiliseconds = 86400000;

    if (!cached || parseInt(cached) + dayInMiliseconds < now) {
      removeCoordsFromLocalStorage();

      localStorage.setItem('coordsCache', now.toString());
    }
  }, []);

  return (
    <Router>
      <Container />
    </Router>
  );
};

export default App;
